import React, {useState, useEffect, Fragment} from 'react'
import { DateTime } from 'luxon';
import RequiredBadge from './RequiredBadge';

const DateField = ({
	label, name, isRequired, field_id, onChangeDate,
	description = '',
	value,
	readOnly = false,
	style,	// This handles calendar config settings
}) => {

	const [ fromId ] = useState('datepicker'+(Math.round(Math.random()*1000).toString()))
	const [ date, setDate ] = useState(value)

	useEffect(() => {

		const config = {
			format: "MM/DD/YYYY",
			showClear: true,
			showTodayButton: true,
			icons: {
				previous: 'fas fa-chevron-left',
				next: 'fas fa-chevron-right',
				today: "far fa-calendar",
				clear: 'fas fa-trash',
				up: "fas fa-arrow-up",
				down: "fas fa-arrow-down"
			}
		}
		if ( style?.futureDates ) {
			config.minDate = value ? value : DateTime.now().toLocaleString();
		}
		$('#'+fromId).datetimepicker(config);
		$(`#${fromId}`).on("dp.change", onChange);

	}, [readOnly])

	const onChange = ({target}) => {
		const { value } = target;
		setDate(value); // display value in field as it is formatted in config above (MM/DD/YYYY)

		var temp = value;
		// var day, month, year;
		if ( value.length > 0 && value.indexOf('/') >= 0 ) {
			// format value sent back to parent component (YYYY-MM-DD)
			temp = value.split("/")
			temp[0] = temp[0].replace(/^0/, '')
			temp[1] = temp[1].replace(/^0/, '')
			temp = DateTime.utc(parseInt(temp[2]), parseInt(temp[0]), parseInt(temp[1])).toISODate()
		}

		onChangeDate(field_id, temp)
	}

	if ( !readOnly ) {
		return (
			<Fragment>  
				{label && (
					<label htmlFor={name}>{label} {isRequired === 1 && <RequiredBadge value={date} />}{' '}</label>
				)}
				{description && (
					<div className="input--description">{description}</div>
				)}
				<input
					className={'form-control'}
					id={fromId}
					type={'text'}
					value={date}
					onChange={onChange}
					name={name}
				/>
				<div className="invalid-feedback" />
			</Fragment>
		)
	} else {
		return (
			<Fragment>
				<label htmlFor={name}>{label} {isRequired === 1 && <RequiredBadge value={value} />}{' '}</label>
				<span>{value}</span>
			</Fragment>
		)
	}
}
export default DateField;