import React, { Fragment } from 'react'
import { classnames } from '../../utils/classnames'
import RequiredBadge from './RequiredBadge'

import './styles/MultiSelect.css'

export const MultiSelect = ({
	label,
	items,
	name,
	onChange,
	selected = [],
	description = '',
	limit = 5,
	empty = 'There are no items to display...',
	className = '',
	isRequired = false,
	readOnly = false,
	style = "dropdown" // dropdown or checkbox
}) => {

	const displayStyle = style ?? "dropdown";

	const toggleOption = (event) => {
		onChange(event)
	}

	if ( !readOnly ) {
		if (!items.length)
			return (
				<div className={className}>
					<label htmlFor={name}>{label} {isRequired === 1 && <RequiredBadge value={selected.length > 0} />}{' '}</label>
					{empty}
				</div>
			)

		return (
			<div className={classnames('Input', className)}>
				<label htmlFor={name}>{label} {isRequired === 1 && <RequiredBadge value={selected.length > 0} />}{' '}</label>
				{description && (
					<div className="input--description">{description}</div>
				)}
				
				{displayStyle == "dropdown" ? (
					<div className="c-multi-select-dropdown">
						<div className="c-multi-select-dropdown__selected">
							<div>{selected.length} selected</div>
						</div>
						<ul className="c-multi-select-dropdown__options">
							{items.map(item => {
								const isSelected = selected.includes(item.id);
								return (
									<li className="c-multi-select-dropdown__option" key={item.id} onClick={() => toggleOption({
										target: {
											name: name,
											value: item.id,
											checked: !isSelected
										}})}>
										<input type="checkbox" name={name} value={"Y"} checked={isSelected} onChange={() => {}} />
										<label htmlFor={name} className="ml-2">{item.name ? item.name : item.title}</label>
									</li>
								)
							})}
						</ul>
					</div>
				) : (
					<>
						{items.map(item => {
							const isSelected = selected.includes(item.id);
							return (
								<Fragment key={item.id}>
									<input type="checkbox" name={name} value={"Y"} checked={isSelected} onChange={() => toggleOption({
										target: {
											name: name,
											value: item.id,
											checked: !isSelected
										}
									})} />
									<label htmlFor={name} className="ml-2">{item.name ? item.name : item.title}</label>
								</Fragment>
							)
						})}
					</>
				)}
			</div>
		)
	} else {

		// print selected option in readonly mode
		let itemList = [];
		items.map(item => {
			const isSelected = selected.includes(item.id);
			if ( isSelected ) {
				itemList.push(item.name ? item.name : item.title)
			}
		})
		return (
			<Fragment>
				<label>{label} {isRequired === 1 && <RequiredBadge value={selected.length > 0} />}{' '}</label>
				{itemList.join(', ')}
			</Fragment>
		)

	}
  
}

export default MultiSelect;
