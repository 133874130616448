import { getJobList, getJobForm, saveLinkForm, saveJobForm } from "../../api"
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query"

// TODO
// get job lists for the current user's company (open, pending, submitted, archived)
/*
export const useJobList = ({ listType }, config) => {
    const listInURL = useRouter().query?.list

    if (listInURL && !listType) {
        listType = parseInt(listInURL)
    }

	// TODO: Confirm this works
	if ( !['open', 'pending', 'submitted', 'archived'].includes(listType) ) {
		
		// TODO: Confirm how throwing an error should be done
		return {
			error: {
				code: 401,
				message: "Invalid list type" +listType;
			}
		}
		
	}

    return useQuery(
		['jobList', { type: listType }],
        () => getJobList(listType),
        mergeConfigs(
			{
				// enabled: !!projectId,
            },
            config
        )
    )
}
*/
export const jobKey = (params) => {
    
	// console.log('keyId:', params)

	// Find the first defined value in order of priority: id -> uuid -> appid
    let keyValue = params.id;
	if ( params.uuid ) {
		keyValue = params.uuid;
	} else if ( params.appid ) {
		keyValue = params.appid;
	}
    
    if (!keyValue) {
        console.warn('No valid key found in params:', params);
    } else {
        // console.log(`Using key: ${keyValue} (from ${Object.entries(params).find(([k, v]) => v === keyValue)?.[0]})`);
    }
    
    return ['form', String(keyValue)];
}

// get the form/job data/results/etc for a specific job using the job id/uuid/share uuid/etc
export const useJobForm = (params, accessToken) => {
	
	// let keyId = String(params.id ?? params.uuid ?? params.appid);

	return useQuery({
		queryKey: jobKey(params),
		queryFn: () => getJobForm(params, accessToken).then((data) => {
			return massageData(data)
		},
		{
			enabled: !!(params && accessToken)
		}),
	})
}



// get the form/job data/results/etc for a specific job using the job id/uuid/share uuid/etc
export const useUpdateJobFormLink = ({ jobId, uuid }, config) => {
	
	// only one of these will be defined
	// a "new" job link (that contains an appid) cannot save incremental changes
    const queryClient = useQueryClient()

    return useMutation(
        (data) => saveLinkForm({ id: jobId, answers: data, uuid }),
		{
			enabled: !!jobId && !isNaN(jobId) && !!uuid,
			onSuccess: (data) => {

				var newAnswerData = {};
				data.results.map(result => {
					var field = result.field;
					newAnswerData[field.id] = processFieldAnswers(field, result)
				});

				// update react-query model of form results (stored field results), answers (form field values), and progress (stats)
				queryClient.setQueryData(jobKey({ id: jobId, uuid }), {
					...queryClient.getQueryData(jobKey({ id: jobId, uuid })),
					
					results: data.results,
					answerData: newAnswerData,
					progress: data.progress
				})
			},
			// onSettled: () => {
			// 	queryClient.invalidateQueries({ queryKey: keyId });
			// }
		}
    )
}

// get the form/job data/results/etc for a specific job using the job id/uuid/share uuid/etc
export const useUpdateJobForm = ({ jobId }, config) => {
    // const jobIdInUrl = useRouter().query?.job_id		// TODO: Find out if I can do this.

    // if (jobIdInUrl && !jobId) {
    //     jobId = parseInt(jobId)
    // }

    const queryClient = useQueryClient()

    return useMutation(
        (data) => saveJobForm({ id: jobId, answers: data }),
		{
			enabled: !!jobId && !isNaN(jobId),
			onSuccess: (data) => {

				var newAnswerData = {};
				data.results.forEach(result => {
					var field = result.field;
					newAnswerData[field.id] = processFieldAnswers(field, result)
				});

				// update react-query model of form results (stored field results), answers (form field values), and progress (stats)
				queryClient.setQueryData(jobKey({ id: jobId }), {
					...queryClient.getQueryData(jobKey({ id: jobId })),
					results: data.results,
					answerData: newAnswerData,
					progress: data.progress
				})
			}
		}
    )
}

// process of an array of [ field_id: { field_id: value} ] pairs. value is setup based off field type (address, choice/radio, text, etc)
const processFieldAnswers = (field, result) => {

	if (field.type === 'address' || field.type === 'address_plus') {
				
		const addr_results = result === null ? '' : JSON.parse(result.value)
		let address_block = {
			company: '',
			name: '',
			phone: '',
			address: '',
			address_2: '',
			parcel_no: '',
			lot_no: '',
			block_no: '',
			county: '',
			id: '',
			city: '',
			state: '',
			zip: '',
			email: ''
		};
		// initialize contact address block for bonding companies with blank bond_no field
		if ( field.type == 10 || field.type == 11 || field.type == 12 ) {
			address_block.bond_no = '';
		}

		return {
			id: field.id,
			address: {
				...address_block,
				...addr_results,
			},
		}
		// console.log('answerData['+field.id+']', answerData[field.id])

	} else {

		let output;
		if (result?.value) {
			
			if (field.type === 'multi-select' ) {
				output = JSON.parse(result.value);
			} else {
				output = result.value;
			}

		} else if (field.defaultValue !== '') {
			output = field.defaultValue;
		} else {
			output = null;
		}

		if (field.type === 'choice' && output == '') {
			return {
				...newAnswerData[field.id],
				value: '',
			}
		}

		return {
			id: field.id, 
			value: output
		}
		// if ( field.type === "assoc_choice" ) {
		// 	if ( newAnswerData[field.id]?.value ) {
		// 		// loop through field choices
		// 		field.choices.forEach(choice => {
		// 			if ( choice.id == answerData[field.id]?.value ) {
		// 				chosen_value = choice.value;
		// 				additional_party = choice.extra;
		// 			}
		// 		})
		// 	}
		// }
	}
}

// configure the response from when a form is initially loaded
const massageData = (data) => {

	var chosen_value = 0;
	var additional_party = 0;

	let limit = 0;
	let answerData = {}

	data.form.sections.forEach(section => {
		section.fields.forEach(field => {

			var result = field?.result;
			answerData[field.id] = processFieldAnswers(field, result);

			// process choices for "customer role" field
			if ( field.type === "assoc_choice" ) {
				
				// value set, meaning the chosen value can be evaluated to determine section visibility, etc.
				if ( answerData[field.id]?.value ) {
					// loop through field choices
					field.choices.forEach(choice => {
						if ( choice.id == answerData[field.id]?.value ) {
							chosen_value = choice.value;
							additional_party = choice.extra;
						}
					})
				}

			}
		})
	})

	// Set each section to "visible", unless an Association Option has been chosen, then show/hide sections accordingly
	/*
	data.form.sections.map(section => {
		if ( chosen_value !== 0 ) {
			var address_found = false
			
			for( var i=0; i<section.fields.length; i++ ) {
				let field = section.fields[i];
				if ( field['type'] == 'address' ) {
					if ( !field.mapped_type || field?.mapped_type?.reference == 'project_location' ) continue;
					// if ( field.type_id == 2 || field.type_id == null ) continue;
					if ( field.type_id > 2 && field.type_id <= 8 ) {
						if (additional_party == 1) {
							section['visible'] = (chosen_value >= field.type_id) ? true : false
						} else {
							section['visible'] = (chosen_value > field.type_id) ? true : false
						}
						address_found = true
					} else {
						// Mark sections that are above 5 in contractual chain to always display
						section['visible'] = true
						address_found = true
					}
				}
			}
			if ( !address_found ) section['visible'] = true
		
		} else {
			section['visible'] = true
		}
	})
	*/

	return {
		...data,
		answerData
	}
}

// TODO: use this in anyway?
// from portal
/*
export const useProject = ({ projectId, takeover = false }, config) => {
    const projectIdInUrl = useRouter().query?.project_id

    if (projectIdInUrl && !projectId) {
        projectId = parseInt(projectIdInUrl)
    }

    return useQuery(
        Project.keys.project(projectId),
        () => Project.get(projectId, takeover),
        mergeConfigs(
            {
                enabled: !!projectId,
            },
            config
        )
    )
}
*/